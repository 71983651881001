import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonType } from '../../../models';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule],
})
export class ButtonComponent {
  @Input({ required: true }) text: string;
  @Input() type: ButtonType = 'primary';
  @Input() disabled = false;
  @Input() icon: string;
  @Input() dataCy: string;
}
