<mat-form-field
  appearance="outline"
  class="border white-background radius-6 custom-arrow outline-dropdown no-padding full-width"
  [attr.data-cy]="dataCy"
  [ngClass]="dropdownPosition"
  (click)="onOpenDropdown()"
>
  <mat-select
    #select
    data-cy="select"
    [panelClass]="'outline-dropdown-panel'"
    [formControl]="formControl"
    [placeholder]="selectedOption ? selectedOption : (placeholder | translate)"
    [value]="selectedOption"
    (closed)="closeDropdown()"
    (selectionChange)="onSelectionChange($event)"
  >
    @for (option of options; track $index) {
      <mat-option data-cy="mat-option" [value]="option.value">
        <span class="black-text">{{ option.name }}</span>
      </mat-option>
    }
  </mat-select>
  <mat-icon class="dropdown-icon" matSuffix svgIcon="chevron-up" />
</mat-form-field>
