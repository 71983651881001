import { UiLanguage } from '../+state/user/models';

export const dayFormat = 'DD/MM/YYYY';
const calendarFormatsEn = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: dayFormat,
};

const calendarFormatsDe = {
  sameDay: '[Heute]', // Today
  nextDay: '[Morgen]', // Tomorrow
  nextWeek: 'dddd', // Name of the day for next week
  lastDay: '[Gestern]', // Yesterday
  lastWeek: '[Letzten] dddd', // Last {day of the week}
  sameElse: dayFormat,
};

export const getCalendarFormats = (language: UiLanguage) => {
  switch (language) {
    case 'de':
      return calendarFormatsDe;
    default:
      return calendarFormatsEn;
  }
};
