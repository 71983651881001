import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatSelect,
  MatSelectChange,
  MatSelectModule,
} from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(formControl: UntypedFormControl | null) {
    return !formControl.valid && formControl.touched;
  }
}
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
  standalone: true,
  imports: [
    NgClass,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DropdownComponent,
      multi: true,
    },
  ],
})
export class DropdownComponent implements OnInit, ControlValueAccessor {
  cdr = inject(ChangeDetectorRef);
  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    value ? this.formControl.disable() : this.formControl.enable();
  }

  @ViewChild('select') select: MatSelect;

  @Input({ required: true }) options: DropdownItem[];
  @Input() placeholder: string;
  @Input() selectedOption = '';
  @Input() dataCy = '';
  @Output() selectionChange = new EventEmitter<string>();
  @Output() openDropdown = new EventEmitter<boolean>();

  matcher = new CustomErrorStateMatcher();
  formControl = new FormControl();
  dropdownPosition: string;

  //  control value access
  onTouched: () => void;
  onChanged: (value: string) => void;

  private _disabled = false;

  ngOnInit() {
    if (this.selectedOption) {
      this.formControl.setValue(this.selectedOption);
    }
  }

  writeValue(option: string) {
    this.selectedOption = option;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validate(formControl: FormControl) {
    this.formControl = formControl;
    const error = {
      require: {
        valid: false,
      },
    };

    return formControl.value ? null : error;
  }

  closeDropdown() {
    this.select._onBlur();
    this.dropdownPosition = null;
    this.openDropdown.emit(false);
  }

  onOpenDropdown() {
    const overlayClass =
      this.select.panel?.nativeElement.offsetParent.classList;
    const positionAbove = overlayClass?.contains('mat-mdc-select-panel-above');

    this.dropdownPosition = positionAbove ? 'panel-above' : 'panel-below';
    this.openDropdown.emit(true);
  }

  public onSelectionChange(event: MatSelectChange) {
    this.selectionChange.emit(event.value);
  }
}

export interface DropdownItem {
  name: string;
  value: string;
}
