import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { SystemVersionEntry, simpleDialogConfig } from 'rio-models';
import { SystemVersionsDialogComponent } from 'rio-ui';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { routerActions } from '../router/router.actions';
import { selectIsUserSuperAdminOrSupport } from '../user/user.selectors';
import { systemActions } from './system.actions';
import { SystemService } from './system.service';

@Injectable()
export class SystemEffects {
  headerLogoClicked$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.headerLogoClicked),
      concatLatestFrom(() =>
        this.store.select(selectIsUserSuperAdminOrSupport),
      ),
      map(
        ([
          { openSystemVersionDialogWhenUserPressesCtrlOrMetaKey },
          isSuperAdminOrSupport,
        ]) => {
          const openSystemVersion =
            openSystemVersionDialogWhenUserPressesCtrlOrMetaKey &&
            isSuperAdminOrSupport;

          return openSystemVersion
            ? systemActions.showVersionsDialog()
            : routerActions.navigateToDefaultRoute();
        },
      ),
    ),
  );

  showVersionsDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(systemActions.showVersionsDialog),
      switchMap(() =>
        this.systemService.getSystemVersions().pipe(
          tap(versions => {
            const dialogConfig: MatDialogConfig<SystemVersionEntry[]> = {
              ...simpleDialogConfig,
              data: versions,
            };

            const dialogRef = this.matDialog.open(
              SystemVersionsDialogComponent,
              dialogConfig,
            );

            return dialogRef.afterClosed();
          }),
          map(() => systemActions.showVersionsDialogSuccess()),
          catchError(() => of(systemActions.showVersionsDialogFailure())),
        ),
      ),
    ),
  );
  constructor(
    private actions$: Actions,
    private store: Store,
    private systemService: SystemService,
    private matDialog: MatDialog,
  ) {}
}
