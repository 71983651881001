import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SystemVersionEntry } from 'rio-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import versions from '../../../../../../apps/versions.json';
import { RESOURCES } from '../../services/resources';
import { API_PREFIX } from '../../utils/injection-tokens';
import { SystemVersionsBackendResponse } from './models';

@Injectable()
export class SystemService {
  API_PREFIX = inject(API_PREFIX);
  http = inject(HttpClient);

  getSystemVersions(): Observable<SystemVersionEntry[]> {
    const url = RESOURCES.SYSTEM.systemVersion();

    return this.http.get<SystemVersionsBackendResponse>(url).pipe(
      map((response): SystemVersionEntry[] =>
        [
          { name: 'FE', value: versions['rio-version'] },
          { name: 'BE', value: response.version },
        ].concat(
          response.component_versions.map(version => ({
            name: version.name,
            value: version.value,
          })),
        ),
      ),
    );
  }
}
