import {} from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconRegistryModule } from '../../../icon-registry.module';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatIconRegistryModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class IconButtonComponent {
  @Input({ required: true }) icon: string;
  @Input({ required: false }) disabled = false;
  @Input() outline = true;
  @Input() text: string;
  @Input() dataCy: string;
  @Input() tooltipPosition:
    | 'left'
    | 'right'
    | 'above'
    | 'below'
    | 'before'
    | 'after' = 'below';
}
